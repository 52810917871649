import React from 'react';
import Header from '../component/common/header';
import Footer from '../component/common/footer';
import { TypeAnimation } from "react-type-animation";
import "./style.scss"
function hanlelOnClickFB() {
    window.open('https://www.facebook.com/tarotx.official/');
}
function hanlelOnClickInstagram() {
    window.open('https://www.instagram.com/tarotx.official/');
}
function hanlelOnClickX() {
    window.open('https://x.com/TarotX_Official');
}
function hanlelOnClickTelegram() {
    window.open('https://t.me/tarotX_official');
}
const HomePage = () => {
    return (
        <>
            <Header />
            <section className="feature-banner">
                <div className="blur-header">
                    <img className="treeLeaves" src="./images/BG-header.png" alt="" />
                </div>
                <div className="blur-box">
                    <img src="./images/Blur.png" alt="" />
                </div>
                <div className="container">
                    <div className="main-banner">
                        <div className="title">
                            <TypeAnimation
                                style={{ whiteSpace: 'pre-line', height: '195px', display: 'block' }}
                                sequence={[
                                    "Guarded",
                                    1000,
                                    `Guarded by a Mysterious Necklace , The Journey Begins`,
                                    1000,
                                    "Guarded",
                                    1000,
                                    `Guarded by a Mysterious Necklace , The Journey Begins`,
                                    1000,
                                ]}
                                wrapper="span"
                                speed={30}
                                repeat={Infinity}
                            />
                        </div>
                        <div className="button-mystery">
                            <button className="btn-mystery">
                                Discover the Mystery
                            </button>
                        </div>
                        <div className="desc">
                            COMING SOON
                        </div>
                        <div className="main-images-tarot">
                            <div className="box-img-big">
                                <img src="./images/img-main.png" alt="" />
                                <div className="box-img-small">
                                    <img src="./images/img-small.png" alt="" />
                                </div>
                                <div className="box-img-gif">
                                    <img src="./images/gif-main.gif" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-destiny" id="tarotx">
                <div className="container">
                    <div className="wrap-destiny">
                        <div className="title">
                            Own Your Destiny with TarotX
                        </div>
                        <div className="desc">
                            Experience the future of Tarot readings with NFTs and blockchain technology.
                        </div>
                        <div className="guide-wrap">
                            <div className="columns">
                                <div className="colum w-4">
                                    <div className="content-wrap">
                                        <div className="icon">
                                            <img src="./images/svg-desc.png" alt="" />
                                        </div>
                                        <div className="text">
                                            Transparent Readings
                                        </div>
                                        <div className="txt">
                                            Verified Readers with transparent profiles and user reviews.
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="content-wrap">
                                        <div className="icon">
                                            <img src="./images/svg-desc.png" alt="" />
                                        </div>
                                        <div className="text">
                                            Virtual Tarot Cards
                                        </div>
                                        <div className="txt">
                                            Interactive and immersive card drawing experience.
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="content-wrap">
                                        <div className="icon">
                                            <img src="./images/svg-desc.png" alt="" />
                                        </div>
                                        <div className="text">
                                            NFT Ownership
                                        </div>
                                        <div className="txt">
                                            Own, trade, and collect unique NFT Tarot cards.
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="content-wrap">
                                        <div className="icon">
                                            <img src="./images/svg-desc.png" alt="" />
                                        </div>
                                        <div className="text">
                                            Instant Booking
                                        </div>
                                        <div className="txt">
                                            Quickly book reputable Readers anytime, anywhere.
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="content-wrap">
                                        <div className="icon">
                                            <img src="./images/svg-desc.png" alt="" />
                                        </div>
                                        <div className="text">
                                            Rewards System
                                        </div>
                                        <div className="txt">
                                            Earn and use TarotX tokens for transactions and rewards.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-explore" id="features">
                <div className="container">
                    <div className="wrap-explore">
                        <div className="title">
                            Explore TarotX Features
                        </div>
                        <div className="desc">
                            Experience the future of Tarot readings with NFTs and blockchain technology.
                        </div>
                        <div className="guide-wrap-explore">
                            <div className="columns">
                                <div className="colum w-4">
                                    <div className="guide-explore">
                                        <div className="content-flip">
                                            <div className="box-img">
                                                <img src="./images/g-1.png" alt="" />
                                            </div>
                                            <div className="line-one lines"></div>
                                            <div className="line-two lines"></div>
                                            <div className="line-three lines"></div>
                                            <div className="line-four lines"></div>
                                            <div className="txt">
                                                Reader Profiles
                                            </div>
                                            <div className="desc-row">
                                                Detailed bios, introductory videos, and ratings.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="guide-explore">
                                        <div className="content-flip">
                                            <div className="box-img">
                                                <img src="./images/g-2.png" alt="" />
                                            </div>
                                            <div className="line-one lines"></div>
                                            <div className="line-two lines"></div>
                                            <div className="line-three lines"></div>
                                            <div className="line-four lines"></div>
                                            <div className="txt">
                                                Interactive Tarot Spreads
                                            </div>
                                            <div className="desc-row">
                                                Virtual shuffling and card selection.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="guide-explore">
                                        <div className="content-flip">
                                            <div className="box-img">
                                                <img src="./images/g-3.png" alt="" />
                                            </div>
                                            <div className="line-one lines"></div>
                                            <div className="line-two lines"></div>
                                            <div className="line-three lines"></div>
                                            <div className="line-four lines"></div>
                                            <div className="txt">
                                                Marketplace
                                            </div>
                                            <div className="desc-row">
                                                Buy, sell, and trade Tarot related items.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="guide-explore">
                                        <div className="content-flip">
                                            <div className="box-img">
                                                <img src="./images/g-4.png" alt="" />
                                            </div>
                                            <div className="line-one lines"></div>
                                            <div className="line-two lines"></div>
                                            <div className="line-three lines"></div>
                                            <div className="line-four lines"></div>
                                            <div className="txt">
                                                Blockchain Security
                                            </div>
                                            <div className="desc-row">
                                                Ensuring transparent and secure transactions.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="guide-explore">
                                        <div className="content-flip">
                                            <div className="box-img">
                                                <img src="./images/g-5.png" alt="" />
                                            </div>
                                            <div className="line-one lines"></div>
                                            <div className="line-two lines"></div>
                                            <div className="line-three lines"></div>
                                            <div className="line-four lines"></div>
                                            <div className="txt">
                                                Token Integration
                                            </div>
                                            <div className="desc-row">
                                                Use TarotX tokens within the platform.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-guardians" id="guardians">
                <div className="container">
                    <div className="guide-guardians">
                        <div className="box-img">
                            <img src="./images/BG-de.png" alt="" />
                            <div className="img-center">
                                <img className="treeLeaves" src="./images/img-center.png" alt="" />
                            </div>
                        </div>
                        <div className="title">
                            Timeless Guardians
                        </div>
                        <div className="desc">
                            Join now to get exclusive early access to rare NFT Characters!
                        </div>
                        <div className="is-comming">
                            COMING SOON
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-tarot-work" id="work">
                <div className="container">
                    <div className="guide-tarot-work">
                        <div className="left">
                            <div className="title">
                                How TarotX Works?
                            </div>
                        </div>
                        <div className="right">
                            <div className="wrap-line">
                                <div className="vector-line">
                                    <img src="./images/vector.png" alt="" />
                                </div>
                                <div className="item">
                                    <div className="images-eclip">
                                        <img src="./images/eclip.png" alt="" />
                                    </div>
                                    <div className="background-line"></div>
                                    <div className="number">
                                        01
                                    </div>
                                    <div className="txt">
                                        Create an Account
                                    </div>
                                    <div className="desc">
                                        Sign up and set up your profile.
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="images-eclip">
                                        <img src="./images/eclip.png" alt="" />
                                    </div>
                                    <div className="background-line"></div>
                                    <div className="number">
                                        02
                                    </div>
                                    <div className="txt">
                                        Explore Readers
                                    </div>
                                    <div className="desc">
                                        Browse and choose from a list of verified Readers.
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="images-eclip">
                                        <img src="./images/eclip.png" alt="" />
                                    </div>
                                    <div className="background-line"></div>
                                    <div className="number">
                                        03
                                    </div>
                                    <div className="txt">
                                        Book a Session
                                    </div>
                                    <div className="desc">
                                        Instantly book a reading at your convenience
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="images-eclip">
                                        <img src="./images/eclip.png" alt="" />
                                    </div>
                                    <div className="background-line"></div>
                                    <div className="number">
                                        04
                                    </div>
                                    <div className="txt">
                                        Own NFTs
                                    </div>
                                    <div className="desc">
                                        Purchase, collect, and trade unique Tarot cards.
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="images-eclip">
                                        <img src="./images/eclip.png" alt="" />
                                    </div>
                                    <div className="background-line"></div>
                                    <div className="number">
                                        05
                                    </div>
                                    <div className="txt">
                                        Earn Rewards
                                    </div>
                                    <div className="desc">
                                        Use TarotX tokens for various platform benefits.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-booking">
                <div className="container">
                    <div className="guide-booking">
                        <div className="left">
                            <div className="title">
                                Try new forms of visual storytelling
                            </div>
                        </div>
                        <div className="right">
                            <div className="desc">
                                Tarot is like a role-playing game, where you map out scenarios in your own life. Every session is a conversation between you, the facilitator, and the evocative artwork. During a session, you can zoom in on the illustrations, read about the archetypes, and chime in with thoughts. Leave with actionable takeaways and clarity.
                            </div>
                            <div className="button-booking">
                                <button className="btn-booking">
                                    Book a session (Coming Soon)
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="section-users">
                <div className="container">
                    <div className="guide-user">
                        <div className="title">
                            What Our Users Say
                        </div>
                        <div className="desc">
                            Quotes and ratings from early adopters and satisfied users.
                        </div>
                        <div className="wrap-user">
                            <div className="columns">
                                <div className="colum w-4">
                                    <div className="content-user">
                                        <div className="box-user">
                                            <div className="top-user">
                                                <div className="img-user">
                                                    <img src="./images/user.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Guillermo Rauch <span>TarotX User</span>
                                                </div>
                                            </div>
                                            <div className="desc-user">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </div>
                                        </div>
                                        <div className="box-user">
                                            <div className="top-user">
                                                <div className="img-user">
                                                    <img src="./images/user.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Guillermo Rauch <span>TarotX User</span>
                                                </div>
                                            </div>
                                            <div className="desc-user">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged
                                            </div>
                                        </div>
                                        <div className="box-user">
                                            <div className="top-user">
                                                <div className="img-user">
                                                    <img src="./images/user.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Guillermo Rauch <span>TarotX User</span>
                                                </div>
                                            </div>
                                            <div className="desc-user">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="content-user">
                                        <div className="box-user">
                                            <div className="top-user">
                                                <div className="img-user">
                                                    <img src="./images/user.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Guillermo Rauch <span>TarotX User</span>
                                                </div>
                                            </div>
                                            <div className="desc-user">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged
                                            </div>
                                        </div>
                                        <div className="box-user">
                                            <div className="top-user">
                                                <div className="img-user">
                                                    <img src="./images/user.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Guillermo Rauch <span>TarotX User</span>
                                                </div>
                                            </div>
                                            <div className="desc-user">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                            </div>
                                        </div>
                                        <div className="box-user">
                                            <div className="top-user">
                                                <div className="img-user">
                                                    <img src="./images/user.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Guillermo Rauch <span>TarotX User</span>
                                                </div>
                                            </div>
                                            <div className="desc-user">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="content-user">
                                        <div className="box-user">
                                            <div className="top-user">
                                                <div className="img-user">
                                                    <img src="./images/user.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Guillermo Rauch <span>TarotX User</span>
                                                </div>
                                            </div>
                                            <div className="desc-user">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </div>
                                        </div>
                                        <div className="box-user">
                                            <div className="top-user">
                                                <div className="img-user">
                                                    <img src="./images/user.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Guillermo Rauch <span>TarotX User</span>
                                                </div>
                                            </div>
                                            <div className="desc-user">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged
                                            </div>
                                        </div>
                                        <div className="box-user">
                                            <div className="top-user">
                                                <div className="img-user">
                                                    <img src="./images/user.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Guillermo Rauch <span>TarotX User</span>
                                                </div>
                                            </div>
                                            <div className="desc-user">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="section-community">
                <div className="container">
                    <div className="wrap-community">
                        <div className="title">
                            Join Our Community
                        </div>
                        <div className="desc">
                            Be part of our vibrant community events and discussions.
                        </div>
                        <div className="guide-community">
                            <div className="columns">
                                <div className="colum w-3" onClick={() => { hanlelOnClickFB() }}>
                                    <div className="content-row">
                                        <div className="item-social">
                                            <div className="line-one lines"></div>
                                            <div className="line-two lines"></div>
                                            <div className="line-three lines"></div>
                                            <div className="line-four lines"></div>
                                            <div className="icon">
                                                <img src="./images/s-1.png" alt="" />
                                            </div>
                                            <div className="text">
                                                Facebook
                                            </div>
                                            {/* <div className="number">
                                                240K Followers
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3" onClick={() => { hanlelOnClickX() }}>
                                    <div className="content-row">
                                        <div className="item-social">
                                            <div className="line-one lines"></div>
                                            <div className="line-two lines"></div>
                                            <div className="line-three lines"></div>
                                            <div className="line-four lines"></div>
                                            <div className="icon">
                                                <img src="./images/s-2.png" alt="" />
                                            </div>
                                            <div className="text">
                                                Twitter
                                            </div>
                                            {/* <div className="number">
                                                240K Followers
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3" onClick={() => { hanlelOnClickTelegram() }}>
                                    <div className="content-row">
                                        <div className="item-social">
                                            <div className="line-one lines"></div>
                                            <div className="line-two lines"></div>
                                            <div className="line-three lines"></div>
                                            <div className="line-four lines"></div>
                                            <div className="icon">
                                                <img src="./images/s-3.png" alt="" />
                                            </div>
                                            <div className="text">
                                                Telegram
                                            </div>
                                            {/* <div className="number">
                                                240K Followers
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3" onClick={() => { hanlelOnClickInstagram() }}>
                                    <div className="content-row">
                                        <div className="item-social">
                                            <div className="line-one lines"></div>
                                            <div className="line-two lines"></div>
                                            <div className="line-three lines"></div>
                                            <div className="line-four lines"></div>
                                            <div className="icon">
                                                <img src="./images/s-4.png" alt="" />
                                            </div>
                                            <div className="text">
                                                Instagram
                                            </div>
                                            {/* <div className="number">
                                                240K Followers
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="box-img-footer">
                <img src="./images/images-bottom.png" alt="" />
            </div>
            <Footer />
        </>
    )
}
export default HomePage
import React from 'react';
import "./style.scss"

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="main-footer">
                        <div className="left">
                            <div className="box-img">
                                <img src="./images/logo.png" alt="" />
                            </div>
                            <div className="title">
                                Join our newsletter
                            </div>
                            <div className="desc">
                                Keep up to date with everything TarotX
                            </div>
                        </div>
                        <div className="right">
                            <div className="menu-footer">
                                <ul className="list-menu">
                                    <li>
                                        <a href="#">Home</a>
                                    </li>
                                    <li>
                                        <a href="#">Features</a>
                                    </li>
                                    <li>
                                        <a href="#">How it works</a>
                                    </li>
                                    <li>
                                        <a href="#">Community</a>
                                    </li>
                                    <li>
                                        <a href="#">Contact Us</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="email-contact">
                                <input type="text" placeholder='Enter your email' />
                                <button className="btn-email">
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="policy">
                        <div className="left">
                            <a href="#">Privacy Policy</a>
                            <a href="#">Terms of Conditions</a>
                        </div>
                        <div className="right">
                            <p>TarotX App, All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer
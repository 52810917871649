import React from 'react';
import HomePage from './homePage';
import './App.css';

function App() {
    return (
        <div className="main-layout">
            <HomePage />
        </div>
    );
}

export default App;

import React, { useEffect, useState } from 'react';
import "./style.scss"

const Header = () => {
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 1);
        });
    }, []);

    return (
        <>
            <header className={scroll ? "header background-row" : "header"}>
                <div className="container">
                    <div className="main-header">
                        <div className="left">
                            <div className="box-img">
                                <img src="./images/logo.png" alt="" />
                            </div>
                        </div>
                        <div className="center">
                            <div className="main-menu">
                                <ul className="list-menu">
                                    <li>
                                        <a href="#tarotx">What's TarotX</a>
                                    </li>
                                    <li>
                                        <a href="#features">Features</a>
                                    </li>
                                    <li>
                                        <a href="#guardians">Timeless Guardians</a>
                                    </li>
                                    <li>
                                        <a href="#work">How TarotX Works</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="right">
                            <div className="row-login">
                                <button className="btn-login">
                                    Login
                                </button>
                                <button className="btn-started">
                                    Get Started
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header